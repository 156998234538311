import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import ForgotPasswordForm from "../components/part/forgotPasswordForm"

const forgotPasswordPage = () => (
  <Layout>
    <SEO title="Reset Password" />
    <Banner title="Reset Password"></Banner>
    <ForgotPasswordForm></ForgotPasswordForm>
  </Layout>
)

export default forgotPasswordPage
