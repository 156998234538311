import React from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { REGISTER } from "../../constants/routes"

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { email: "", error: null }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleInputChange(event) {
    const target = event.target
    this.setState({ [target.name]: target.value })
  }
  async handleSubmit(event) {
    event.preventDefault()
    // const { email } = this.state
    // const result = await Users.login({ email })
    // if (!result) return this.setState({ error: true })
    // this.props.dispatch({ type: xx, payload: await result.json() })
    // navigate("/profile")
  }

  render() {
    return (
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div
              className="accordion accordion-lg mx-auto mb-0 clearfix"
              style={{ maxWidth: "550px" }}
            >
              <div
                className="accordion-header"
                style={{ marginBottom: "24px" }}
              >
                <div className="accordion-icon">
                  <i className="icon-user4" style={{ color: "#8f8f8f" }} />
                </div>
                <div className="accordion-title login-title">My Account</div>
              </div>
              <div className="clearfix">
                <form
                  id="login-form"
                  name="login-form"
                  className="row mb-0"
                  onSubmit={this.handleSubmit}
                >
                  <div className="col-12 form-group">
                    <label htmlFor="login-form-email">E-mail</label>
                    <div className="input-group">
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="icon-email3" />
                        </div>
                      </div>
                      <input
                        type="text"
                        id="login-form-email"
                        name="email"
                        className="form-control"
                        placeholder="yourname@mail.com"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 form-group mar-log">
                    <button
                      className="button button-3d button-circle button-log gradient-grey-orange mr-0 text-center"
                      id="reset"
                      name="reset"
                      value="reset"
                      style={{ width: "100%" }}
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="col-12 form-group">
                    <Link
                      to={REGISTER}
                      className="button button-circle button-border button-white button-reg mr-0 text-center"
                    >
                      Register New Account
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default connect()(ForgotPasswordForm)
